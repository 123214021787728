import * as Sentry from '@sentry/browser';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import ReceiptDropzone from './modules/receipt-dropzone';
import $ from 'jquery';
import Chart from 'chart.js';
import { cookieBanner } from './cookie';

require('jquery-ui');

if (process.env.ENABLE_SENTRY_INTEGRATION === 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [],
  });
}

Rails.start();
Turbolinks.start();

const modules = [
  {
    name: 'receipt-dropzone',
    class: ReceiptDropzone,
    options: {},
  },
];

function loadModules() {
  modules.forEach(function(config) {
    const selector = `[data-module*="${config.name}"]`;

    [].slice.call(document.querySelectorAll(selector)).forEach(function(element) {
      new config.class(element, selector, config.options);
    });
  });

  expandNav();
  mediaQuery();
  salesChart();
  salesChartToggle();
  expensesChart();
  notificationFadeOut();
  notificationClose();
  expandSlidebar();
  releaseReceipts();
  cookieBanner();
}

if (Turbolinks && !Turbolinks.supported) {
  loadModules();
}

document.addEventListener('turbolinks:load', function() {
  loadModules();
});

document.addEventListener('turbolinks:before-render', function(event) {
  // Reset Userlike to ensure chat button is displayed on every page
  if ('userlike' in window) {
    delete window.userlike;
    delete window.__USERLIKE_MOUNT_GUARD__;
  }
});

function notificationFadeOut() {
  var message = $('.smtx__notification--success');

  message.fadeIn(function() {
    $(this).delay(3000).fadeOut(250);
  });
}

function notificationClose() {
  var trigger = $('.smtx__notification--error .close');

  trigger.click(function() {
    $(this).parent().fadeOut(250);
  });
}

$(window).resize(function() {
  mediaQuery();
  let logo = $('#app-logo');
  let entryTitle = $('.entry__title');
  let speech = $('.nav__speech');
  let accountPic = $('.account__pic');
  let accountName = $('.account__name');

  if ( window.matchMedia( '(min-width: 1px) and (max-width: 740px)' ).matches ) {
    logo.css('display', 'block');
    entryTitle.css('display', 'inline');
    speech.css('display', 'block');
    accountName.css('display', 'block');
    accountPic.css('display', 'block');

  } else if ( window.matchMedia( '(min-width: 741px) and (max-width: 1366px)' ).matches ) {
    logo.css('display', 'none');
    entryTitle.css('display', 'none');
    speech.css('display', 'none');
    accountName.css('display', 'none');
    accountPic.css('display', 'none');

  } else if ( window.matchMedia( '(min-width: 1367px)' ).matches ) {
    logo.css('display', 'block');
    entryTitle.css('display', 'inline');
    speech.css('display', 'block');
    accountName.css('display', 'block');
    accountPic.css('display', 'block');
  }
});

function releaseReceipts() {
  var trigger = $('#release-receipt');
  var backTrigger = $('#back-to-receipts');
  var overlay = $('#confirm-receipts');

  trigger.click(function() {
    overlay.toggleClass('disabled');
  })

  backTrigger.click(function() {
    overlay.toggleClass('disabled');
  })
}

function expandNav() {
  var trigger = $('#app-burger');
  var burger = $('#app-burger');
  var logo = $('#app-logo');
  var entryTitle = $('.entry__title');
  var speech = $('.nav__speech');

  var accountPic = $('.account__pic');
  var accountName = $('.account__name');

  trigger.click(function() {
    if ( window.matchMedia( '(min-width: 1px) and (max-width: 740px)' ).matches ) {

      $('body').toggleClass( 'show--nav' );
      burger.toggleClass( 'is-active' );
      logo.css( 'display', 'block' ).css( 'opacity', '1' );

    } else if ( window.matchMedia( '(min-width: 741px) and (max-width: 1366px)' ).matches ) {

      $('body').toggleClass( 'show--nav' );
      burger.toggleClass( 'is-active' );
      logo.animate( { 'opacity': 'toggle', 'left': 'toggle' }, 200 );
      entryTitle.animate( { 'width': 'toggle' }, 200 );
      speech.animate( { 'opacity':'toggle', 'left': 'toggle', }, 200 );
      accountPic.delay( 100 ).animate( { 'opacity':'toggle', 'width':'toggle' }, 100 );
      accountName.delay( 200 ).animate( { 'opacity': 'toggle', 'width':'toggle' }, 100 );

    }
  });
}

function salesChartToggle() {
  var chartToggle = $('.widget__toggle');
  var lineChart = $('#chart-umsatz-line');
  var barChart = $('#chart-umsatz-bar');
  var lineChartIcon = $('.toggle--line');
  var barChartIcon = $('.toggle--bar');

  chartToggle.click(function() {
    lineChart.toggleClass('is-inactive is-active');
    barChart.toggleClass('is-inactive is-active');
    lineChartIcon.toggleClass('is-inactive is-active');
    barChartIcon.toggleClass('is-inactive is-active');
  });
}

function expandSlidebar() {
  var slideTrigger = $('#slide-trigger');
  var closeSlide = $('.slide-close');
  var slidebar = $('.widget.widget__side-wrapper');

  slideTrigger.click(function() {
    if ( window.matchMedia( '(min-width: 1px) and (max-width: 1800px)' ).matches ) {
      slidebar.addClass('is-active');
    }
  });

  closeSlide.click(function() {
    if ( window.matchMedia( '(min-width: 1px) and (max-width: 1800px)' ).matches ) {
      slidebar.removeClass('is-active');
    }
  })
}

function mediaQuery() {
  if ( window.matchMedia( '(min-width: 1367px)' ).matches ) {
    $( ".widget__info-trigger" ).tooltip({
      tooltipClass: "smtx__tooltip",
      position: {
        my: "center-10 top+25",
        at: "right bottom",
      },
      content: function () {
        return $(this).prop('title');
      },
    });
  } else if ( window.matchMedia( '(min-width: 768px) and (max-width: 1366px)' ).matches ) {
    $( ".widget__info-trigger" ).tooltip({
      tooltipClass: "smtx__tooltip",
      position: {
        my: "right+40 top+25",
        at: "right bottom",
      },
      content: function () {
        return $(this).prop('title');
      },
    });
  } else if ( window.matchMedia( '(min-width: 1px) and (max-width: 767px)' ).matches ) {
    $( ".widget__info-trigger" ).tooltip({
      tooltipClass: "smtx__tooltip",
      position: {
        my: "right+25 top+25",
        at: "right bottom",
      },
      content: function () {
        return $(this).prop('title');
      },
    });
  }
}

function salesChart() {
  var elLine = document.getElementById('chart-umsatz-line');
  var elBar = document.getElementById('chart-umsatz-bar');
  var ctxLine;
  var ctxBar;

  if (elLine) {
    ctxLine = elLine.getContext('2d');
  } else {
    return;
  };

  if (elBar) {
    ctxBar = elBar.getContext('2d');
  } else {
    return;
  }

  var dataSets = JSON.parse(elBar.getAttribute('data-sets'));
  var labels = JSON.parse(elBar.getAttribute('data-labels'));

  var gradientFill = ctxLine.createLinearGradient(0,0,0,265);
  gradientFill.addColorStop(0, 'rgba(171, 226, 207, 0.9)');
  gradientFill.addColorStop(1, 'rgba(171, 226, 207, 0)');

  var salesDataLine = {
    labels: labels,
    datasets: [{
      label: dataSets[0].label,
      borderColor: 'rgba(171, 226, 207, 1)',
      backgroundColor: gradientFill,
      fill: true,
      data: dataSets[0].values,
      borderWidth: 4,
      pointBackgroundColor: 'rgba(171, 226, 207, 1)',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      pointHoverBorderWidth: 6,
      pointRadius: 5,
      pointHoverRadius: 6,
      pointBorderWidth: 4,
    }, {
      label: dataSets[1].label,
      borderColor: 'rgba(255,131,100,1)',
      backgroundColor: 'rgba(255,131,100,1)',
      fill: false,
      data: dataSets[1].values,
      borderWidth: 4,
      pointBackgroundColor: 'rgba(255,131,100,1)',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      pointHoverBorderWidth: 6,
      pointRadius: 5,
      pointHoverRadius: 6,
      pointBorderWidth: 4,
    }]
  };

  var salesDataBar = {
    labels: labels,
    datasets: [{
      label: dataSets[0].label,
      data: dataSets[0].values,
      backgroundColor: 'rgba(171, 226, 207, 1)',

    }, {
      label: dataSets[1].label,
      data: dataSets[1].values,
      backgroundColor: 'rgba(255,131,100,1)',
    }]
  };

  var chartLayout = {
    padding: {
      left: 10,
      right: 10,
      top: 50,
      bottom: 60,
    },
  };

  var chartTooltips = {
    cornerRadius: 10,
    xPadding: 20,
    yPadding: 20,
    backgroundColor: 'rgba(23,55,92,1)',
    caretSize: 5,
    titleFontFamily: 'Gilroy, sans-serif',
    bodyFontFamily: 'Gilroy, sans-serif',
    displayColors: false,
    intersect: false,
    position: 'nearest',
    callbacks: {
      label: function(item, data) {
        var xLabel = data.datasets[item.datasetIndex].label;
        var yLabel = item.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
        return xLabel + ': ' + yLabel;
      }
    }
  };

  var optionsLine = {
    maintainAspectRatio: false,
    responsive: true,
    hoverMode: 'index',
    stacked: true,
    animation: {
      easing: 'easeInOutQuad',
      duration: 520
    },

    layout: chartLayout,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          min: 0,
        },
      }],
      xAxes: [{
        display: false,
      }],
    },
    tooltips: chartTooltips,
    elements: {
      line: {
        tension: 0.3,
      }
    },
    point: {
      backgroundColor: 'white',
    }
  };

  var optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    hoverMode: 'index',
    stacked: true,
    animation: {
      easing: 'easeInOutQuad',
      duration: 520
    },
    layout: chartLayout,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltips: chartTooltips,
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          min: 0,
        },
      }],
      xAxes: [{
        display: false,
      }],
    },
  };

  Chart.Line(ctxLine, {
    data: salesDataLine,
    options: optionsLine,
  });

  Chart.Bar(ctxBar, {
    data: salesDataBar,
    options: optionsBar,
  });
}

function expensesChart() {
  var el = document.getElementById('chart-top-ausgaben');
  var ctx;

  if (el) {
    ctx = el.getContext('2d');
  } else {
    return;
  };

  var dataSet = JSON.parse(el.getAttribute('data-set'));
  var data = dataSet.map(set => set.value);
  var labels = dataSet.map((set) => set.label);

  var chartData = {
    labels: labels,
    datasets: [{
      data: data,
      backgroundColor: [
        'rgba(255,131,100, 1.0)',
        'rgba(255,131,100, 0.75)',
        'rgba(255,131,100, 0.5)',
        'rgba(255,131,100, 0.25)',
        'rgba(255,131,100, 0.10)',
      ]
    }],
  };

  var chartLayout = {
    padding: {
      left: 20,
      right: 20,
      top: 60,
      bottom: 30,
    },
  };

  var chartTooltips = {
    cornerRadius: 10,
    xPadding: 20,
    yPadding: 20,
    backgroundColor: 'rgba(23,55,92,1)',
    caretSize: 5,
    titleFontFamily: 'Gilroy, sans-serif',
    bodyFontFamily: 'Gilroy, sans-serif',
    displayColors: false,
    intersect: false,
    position: 'nearest',
    callbacks: {
      label: function(item, data) {
        var xLabel = data.labels[item.index];
        var yLabel = data.datasets[0].data[item.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
        return xLabel + ': ' + yLabel;
      }
    }
  };

  var options = {
    maintainAspectRatio: false,
    responsive: true,
    hoverMode: 'index',
    stacked: true,
    animation: {
      easing: 'easeInOutQuad',
      duration: 520
    },
    legend: {
      display: false,
    },
    layout: chartLayout,
    tooltips: chartTooltips,
  };

  Chart.Doughnut(ctx, {
    data: chartData,
    options: options,
  });
}
