import '/home/deployer/apps/smatax-production/shared/vendor/bundle/ruby/2.7.0/gems/mountable_file_server-3.0.3/vendor/assets/javascripts/mountable_file_server.js';

const MountableFileServerUploader = window.MountableFileServerUploader;

// Attention: When a new build is made of the asset package only files with actual changes get re-evaluated.
// This may lead to issues with values taken from Ruby variables as they do not trigger a change.
const defaultUrl = '/files';

const Uploader = function(options) {
  options = options || {};
  options.url = options.url || defaultUrl;

  return new MountableFileServerUploader(options);
};

delete window.MountableFileServerUploader;

export { Uploader };
