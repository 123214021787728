export default class Module {
  constructor(element, selector, options) {
    this.element = element;
    this.selector = selector;
    this.options = options;
    this.bindings = [];

    this.setup();
  }

  setup() {
    throw new Error(`${this.constructor.name}#setup needs to be implemented.`);
  }

  // Alternative invokation:
  // on(event, callback)
  on(event, selector, callback) {
    if (typeof selector === 'function') {
      callback = selector;
      selector = undefined;
    }

    let scope;
    [selector, scope] = this._resolveEventBindingSelector(selector);

    const listener = function(event) {
      if (!selector) {
        callback(event);
      } else if (event.target.matches(selector) || event.target.closest(selector)) {
        callback(event);
      }
    }

    event.split(' ').forEach((_event) => {
      scope.addEventListener(_event, listener);

      this.bindings.push({
        scope: scope,
        event: _event,
        listener: listener,
        selector: selector,
      });
    });
  }

  // Alternative invokation:
  // off(event)
  off(event, selector) {
    let scope;
    [selector, scope] = this._resolveEventBindingSelector(selector);

    event.split(' ').forEach((_event) => {
      this.bindings.filter((binding) => {
        return binding.scope == scope && binding.event == _event && binding.selector == selector;
      }).forEach((binding) => {
        binding.scope.removeEventListener(binding.event, binding.listener);
        this.bindings.splice(this.bindings.indexOf(binding), 1);
      });
    });
  }

  trigger(event, element, detail) {
    event.split(' ').forEach((_event) => {
      const customEvent = new CustomEvent(_event, {
        bubbles: true,
        cancelable: true,
        detail: detail
      });

      if (element instanceof NodeList) {
        Array.from(element).forEach(_element => {
          _element.dispatchEvent(customEvent);
        });
      } else {
        element.dispatchEvent(customEvent);
      }
    });
  }

  _resolveEventBindingSelector(selector) {
    let scope;

    if (selector && typeof selector !== 'string') {
      [selector, scope] = [selector.selector, selector.scope];
    } else {
      scope = this.element;
    }

    return [selector, scope];
  }
}
