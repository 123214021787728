import Module from '../lib/module';
import { Uploader } from '../lib/mountable-file-server.js';

export default class ReceiptDropzone extends Module {
  setup() {
    this.fileField = this.element.querySelector('input[type="file"]');
    this.statusMessage = this.element.querySelector('[data-role="status-message"]');
    this.fileList = this.element.querySelector('[data-role="file-list"]');
    this.listItemTemplate = this.element.querySelector('[data-role="list-item-template"]');
    this.receiptForm = this.element.querySelector('[data-role="receipt-form"]');
    this.wrapper = this.element.closest('[data-role="dropzone-wrapper"]');

    this.isDraggingWithinWindow = false;

    this.state = {
      counters: {
        started: 0,
        completed: 0,
      },
    };

    this.bind();
    this.updateReceiptsSubmitButton();
  }

  bind() {
    this.on('change', 'input[type="file"]', (event) => {
      this.uploadMultiple(event.target.files);
    });

    this.on('drag dragstart dragend dragover dragenter dragleave drop', (event) => {
      event.preventDefault();
    });

    this.on('dragover dragenter', () => {
      this.wrapper.classList.add('is-dragover');
    });

    this.on('dragleave dragend drop', () => {
      this.wrapper.classList.remove('is-dragover');

      if (event.type == 'dragend' || event.type == 'drop') {
        this.wrapper.classList.remove('is-highlighted');
      }
    });

    this.on('drop', (event) => {
      this.uploadMultiple(event.dataTransfer.files);
    });

    this.on('dragover dragenter', { scope: document }, (event) => {
      this.isDraggingWithinWindow = true;
      this.wrapper.classList.add('is-highlighted');
    });

    this.on('dragleave', { scope: document }, (event) => {
      // There's no proper way to detect if the window/document is left.
      // 'dragleave' event is triggered on every DOM element that is left
      // with the mouse and then bubbles up.
      // In order to prevent flickering in the UI we use the fact that 'dravoger'
      // is triggered every few hundred milliseconds as long as a file is dragged
      // and delay removing the highlighted style.
      this.isDraggingWithinWindow = false;

      window.setTimeout(() => {
        if (!this.isDraggingWithinWindow) {
          this.wrapper.classList.remove('is-highlighted');
        }
      }, 150);
    });

    this.on('dragend drop', { scope: document }, (event) => {
      this.wrapper.classList.remove('is-highlighted');
    });

    // Use Rails UJS to trigger the request and just handle the response here
    this.on('ajax:complete', '[data-action="remove-receipt"][data-remote="true"]', (event) => {
      this._handleReceiptRemoved(event.detail[0]);
    })
  }

  unbind() {
    this.off('change', 'input[type="file"]');
    this.off('drag dragstart dragend dragover dragenter dragleave drop');
    this.off('dragover dragenter dragleave dragend drop', { scope: document });
    this.off('ajax:complete', '[data-action="remove-receipt"][data-remote="true"]');
  }

  uploadMultiple(files) {
    const options = {
      url: this.element.getAttribute('data-upload-url'),
      type: this.element.getAttribute('data-upload-type') || 'public',
    };
    const uploader = new Uploader({ url: options.url });

    Array.from(files).forEach(file => {
      uploader.uploadFile({
        file: file,
        type: options.type,
        onStart: this._onUploadStart.bind(this),
        onProgress: this._onUploadProgress.bind(this),
        onSuccess: this._onUploadSuccess.bind(this),
      });
    });
  }

  recordReceipt(data) {
    const url = this.receiptForm.getAttribute('action');
    const method = this.receiptForm.getAttribute('method');
    const formData = new FormData(this.receiptForm);

    Object.keys(data).forEach((key) => {
      let value = data[key];

      if (!['string', 'number', 'boolean'].includes(typeof value)) {
        value = JSON.stringify(value);
      }

      formData.append(`receipt[${key}]`, value);
    });

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const onRequestProgress = function(event) {
        const request = event.currentTarget;

        if (request.readyState != XMLHttpRequest.DONE) {
          return;
        }

        if (request.status >= 200 && request.status < 300) {
          resolve({
            status: request.status,
            body: request.response
          });
        } else {
          reject({
            status: request.status,
            body: request.response
          });
        }
      }

      xhr.open(method, url, true);
      xhr.addEventListener('load', onRequestProgress);
      xhr.addEventListener('error', onRequestProgress);
      xhr.send(formData);
    });
  }

  appendToFileList(receipt) {
    const markup = this.listItemTemplate.innerHTML
      .replace(/\{name\}/g, receipt.original_filename)
      .replace(/\{id\}/g, receipt.id);

    this.fileList.insertAdjacentHTML('beforeend', markup);
  }

  removeFromFileList(item) {
    item.remove();
  }

  // FIXME: This should not be handled by the dropzone
  //        but by a separate mechanism
  updateReceiptsSubmitButton() {
    const submitButton = document.querySelector('[data-role="receipts-submission-form"] button');
    const listItemCount = document.querySelectorAll('[data-module="receipt-dropzone"] [data-role="list-item"]').length;

    if (listItemCount > 0) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  _onUploadStart() {
    this.state.counters.started += 1;
    this._updateState();
  }

  _onUploadProgress(progress) {
  }

  _onUploadSuccess(response) {
    this.state.counters.completed += 1;
    this._updateState();

    this.recordReceipt({
      fid: response.fid,
      size: response.metadata.size,
      original_filename: response.original_filename,
    }).then((response) => {
      this.appendToFileList(JSON.parse(response.body));
      this.updateReceiptsSubmitButton();
    }).catch((response) => {
      const responseBody = JSON.parse(response.body);
      alert(responseBody.details);
    });
  }

  _handleReceiptRemoved(xhr) {
    if (xhr.status >= 200 && xhr.status <= 300) {
      this.removeFromFileList(event.target.closest('[data-role="list-item"]'));
      this.updateReceiptsSubmitButton();
    } else {
      const responseBody = JSON.parse(xhr.response.body);
      alert(responseBody.details);
    }
  }

  _updateState() {
    let message;

    if (this.state.counters.completed == this.state.counters.started) {
      message = `${this.state.counters.completed}/${this.state.counters.started} abgeschlossen`;
    } else {
      message = `Upload läuft … ${this.state.counters.completed}/${this.state.counters.started} abgeschlossen`;
    }

    this.statusMessage.textContent = message;
  }
}
